import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid justify-content-center" }
const _hoisted_2 = { class: "col-12 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldServiceHeaderButtons = _resolveComponent("FieldServiceHeaderButtons")!
  const _component_FieldServiceDataWrapper = _resolveComponent("FieldServiceDataWrapper")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Card, null, {
          content: _withCtx(() => [
            _createVNode(_component_FieldServiceHeaderButtons, {
              title: "Service History",
              "new-button-text": "New Field Service",
              "show-print-button": true,
              showClearFilterButton: true,
              lastUpdated: _ctx.lastUpdated,
              "show-new-button": true,
              onClearFilters: _ctx.clearFilters,
              onPrintButtonClicked: _ctx.printExportData,
              onNewButtonClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleNewFieldService(false)))
            }, null, 8, ["lastUpdated", "onClearFilters", "onPrintButtonClicked"]),
            _createVNode(_component_FieldServiceDataWrapper, {
              ref: "fieldServiceTable",
              isResultView: _ctx.isResultView,
              showCustomer: false,
              "current-view": "customers-field-services",
              onCustomerViewClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleNewFieldService(true, $event))),
              onLastUpdated: _ctx.isDataUpdated,
              printExportData: _ctx.printExportData,
              clearFilters: _ctx.clearFilters,
              maxExportRows: 1000,
              preSelectedCustomer: { ..._ctx.getCurrentCustomer }
            }, null, 8, ["isResultView", "onLastUpdated", "printExportData", "clearFilters", "preSelectedCustomer"])
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_Footer)
  ], 64))
}